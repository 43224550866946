<template>
    <div class="container-fluid">
        <Head></Head>
        <div class="row">
            <div class="col-lg-12" style="top: -180px;overflow: hidden;">
                <div class="it_title">
                    <h1 class="it01">IT ASSET DISPOSITION</h1>
                </div>
            </div>

            <div class="col-lg-12"
                 style="margin-top: -105px;height: 855px;width: 1980px;overflow: hidden;">

                <div class="col-lg-6" style="float: left;background-color: wheat;margin-left: -30px">
                    <div class="it_left">
                        <img alt=""
                             src="../../../assets/image/it/1.webp">
                    </div>
                </div>

                <div class="col-lg-6" style="float: left;margin-top: 175px">
                    <div class="it_world">
                        <h3 class="it02">IT ASSET DISPOSITION Service</h3>
                        <div class="it_world_middle">
                            <p class="it02">Cino recycling expertise is certified by the most rigorous industry standards and audited
                                regularly to ensure</p>
                            <p class="it02" style="margin-top: -12px">professional care of your retired IT products. At every stage
                                of</p>
                            <p  class="it02" style="margin-top: -12px">our process, we take care to minimize carbon footprint,</p>

                        </div>
                        <div class="it_world_last">
                            <p class="it02">pollution and toxic waste.</p>
                            <ul>
                                <li class="it02" >We collect, pack and transport products to our secure</li>
                            </ul>
                            <p class="it02" style="margin-top: -35px">facilities for full reporting</p>
                            <ul>
                                <li class="it02">Hardware is wiped clean of any data and refurbished.</li>
                                <li class="it02">Our methods follow top environmental and security</li>
                            </ul>
                            <p class="it02" style="margin-top: -35px">standards.</p>
                            <ul>
                                <li class="it02">Products are recycled and segregated and as follows:</li>
                                <li class="it02">Parts in good working condition are refurbished.</li>
                                <li class="it02">Unusable components are separated to yield clean</li>
                            </ul>
                            <p class="it02" style="margin-top: -35px">commodities</p>
                            <ul>
                                <li class="it02" style="line-height: 1.4rem"> Plastic, steel, aluminium, copper and precious metals
                                    are turned into 100%
                                    recyclable products
                                </li>
                                <li class="it02" style="margin-top: 2px;line-height: 1.4rem">You receive a Certificate of Recycling
                                    that certifies title
                                    transfer and full
                                    compliance with all environmental regulations.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>


            <div class="col-lg-12"
                 style="margin-top: 0px;height: 855px;width: 1980px;overflow: hidden">


                <div class="col-lg-6" style="float: left;margin-top: 175px">
                    <div class="it_world">
                        <h3 class="it03">IT Recycling scope</h3>
                        <div class="it_world_middle">
                            <p class="it03">CINO Recycling provides IT Asset Disposition services for all IT products found in the
                                business environment:</p>

                        </div>
                        <div class="it_world_last">

                            <ul>
                                <li class="it03">Desktop Computers (including keyboard, mouse and wiring)</li>
                                <li class="it03">Laptops</li>
                                <li class="it03">Servers</li>
                                <li class="it03">Mainframe computers and components</li>
                                <li class="it03">Storage Media</li>
                                <li class="it03">Monitors (CRT and LCD )</li>
                                <li class="it03">Printers, Scanners, Drives and Modems</li>
                                <li class="it03">Routers and Switches</li>
                                <li class="it03">Handheld Devices</li>
                                <li class="it03">Telephones (Mobile or Cellular Hardware)</li>
                                <li class="it03">Telecommunications equipment (switch-gear, switches and relays)</li>
                                <li class="it03">Fax Machines</li>
                                <li class="it03">Mass Storage Arrays</li>
                                <li class="it03">Tape Libraries</li>
                                <li class="it03">Point of Sale Equipment</li>
                                <li class="it03">Networking Hardware (Switches, Routers and Hubs )</li>

                            </ul>

                        </div>
                    </div>
                </div>

                <div class="col-lg-6" style="float: left;">
                    <div class="it_left" style="width: 110%;margin-left: -45px">
                        <img
                                src="../../../assets/image/it/2.webp">
                    </div>
                </div>

            </div>
        </div>
        <Bottom style="margin-left: -35px"></Bottom>
    </div>
</template>

<script>
    import {Bottom, Head} from "../commponents";

    export default {
        name: "Itciorecycle",
         metaInfo: {
        meta: [{                 
            name: '暹诺回收官网,手机回收,笔记本回收,数码产品回收,企业回收',
            content: '上海暹诺环保科技有限公司 (简称: 暹诺环保©)是中国领先的环保解决方案提供商，为国内500多家商业和工业企业客户提供回收服务。我们投资开发电子垃圾解决方案，用以适应不断变化的世界。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全的方案，以帮助我们的客户实现零废物的绿色目标。作为中国领先的电子废弃物管理服务供应商，我们的服务宗旨是在最大限度降低影响的同时，又能最大限度地提高资源价值，为所有利益相关者促进经济和环境的可持续发展。'
        }]
        },
        components: { Bottom, Head},
        created() {
            $(function () {
                $('.it01').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)


                $(window).scroll(function () {
                    var s = $(window).scrollTop();
                    console.log(s)
                    if (s > 50) {
                        $('.it02').animate({
                            opacity: '1',
                            top: '-15px'
                        }, 1000)
                    }
                    if (s > 950) {
                        $('.it03').animate({
                            opacity: '1',
                            top: '-15px'
                        }, 1000)
                    }


                });


            })
        }
    }


    $(function () {
        $('.it01').animate({
            opacity: '1',
            top: '15px'
        }, 1000)


        $(window).scroll(function () {
            var s = $(window).scrollTop();
            console.log(s)
            if (s > 50) {
                $('.it02').animate({
                    opacity: '1',
                    top: '-15px'
                }, 1000)
            }
            if (s > 950) {
                $('.it03').animate({
                    opacity: '1',
                    top: '-15px'
                }, 1000)
            }


        });


    })

</script>

<style scoped>

    .it01{
        opacity: 0;
    }
    .it02{
        opacity: 0;
        position: relative;
    }
    .it03{
        position: relative;
        opacity: 0;
    }

    .col-lg-12 .it_title {
        height: 100px;
        width: 100%;

        text-align: center;
    }

    .it_title h1 {
        color: black;
        font-weight: 800;
    }

    .col-lg-12 .col-lg-6 .it_left {
        height: 100%;
        width: 102%;
        overflow: hidden;
        background-color: black;
    }

    .it_left img {
        height: 100%;
        width: 100%;
    }

    .col-lg-6 .it_world {
        height: 100%;
        width: 700px;


    }

    .it_world h3 {
        color: black;
        text-align: center;
        height: 40px;
        width: 100%;
        position: relative;
        margin-top: 0px;

    }

    .it_world .it_world_middle {
        height: 100px;
        width: 70%;

        text-align: center;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        cursor: pointer;
        margin-top: 45px;
    }

    .it_world_middle p {
        color: black;
        line-height: 1.4em;
        font-size: 15px;
        font-style: inherit;
        font-weight: inherit;
        font-family: Arial, Helvetica, sans-serif;
    }

    .it_world .it_world_last {
        height: 380px;
        width: 70%;
        position: relative;
        left: 50%;


        transform: translate(-50%);
    }

    .it_world_last p {
        color: #333333;
        font-size: 17px;
        text-align: center;
    }


    .it_world_last ul {
        display: inline-block;
        width: 100%;
    }

    .it_world_last ul li {

        width: 100%;
        margin-top: -15px;
        color: #333333;
    }

    .it_world_last ul li::before {

        content: "";
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background-color: black;
        position: absolute;
        margin-left: -12px;
        margin-top: 7px;
        opacity: .7;

    }

</style>